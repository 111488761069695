import React from 'react';
import { Button } from '@outdoorly/button';
import { Stack } from '@chakra-ui/react';
import { GoogleIcon } from '@outdoorly/icons';

import { useAuth } from '../../providers/auth';

export const SocialLogin: React.FC<unknown> = () => {
  const auth = useAuth();

  return (
    <Stack direction="row" gap={1} justify="center">
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<GoogleIcon />}
        onClick={auth.loginWithGoogle}
        // css={{ flex: 1 }}
      >
        Log in with Google
      </Button>
      {/* <IconButton
        variant="outline"
        colorScheme="gray"
        icon={<FaFacebookIcon width={7} height={7} color="#1773EA" />}
        onClick={auth.loginWithFacebook}
        css={{ flex: 1 }}
      />
      <IconButton
        variant="outline"
        colorScheme="gray"
        icon={<AppleIcon />}
        onClick={() => {}}
        css={{ flex: 1 }}
        isDisabled
      /> */}
    </Stack>
  );
};
