import React, { useState, useCallback } from 'react';
import { Button } from '@outdoorly/button';
import { Text } from '@outdoorly/text';
import { Field, TextInput } from '@outdoorly/input';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { AuthHeader } from './shared';
import { AuthWrapper } from './auth-wrapper';

import { EMAIL_REGEX } from '../../globals';
import { sendResetPasswordEmail } from '../../providers/auth';

export type RequestPasswordResetProps = {
  handleBackClick: () => void;
  handleCloseClick: () => void;
  isLoading?: boolean;
  onLogin: () => void;
};

export const RequestPasswordReset: React.FC<RequestPasswordResetProps> = ({
  handleCloseClick,
  handleBackClick,
}) => {
  const {
    register,
    handleSubmit,
    errors: formErrors,
    formState,
    setValue,
  } = useForm();
  const [globalError, setGlobalError] = useState();

  const submit = useCallback(async (values) => {
    sendResetPasswordEmail(values.email);
    // Reset any errors which might be present
    setGlobalError(undefined);
  }, []);

  return (
    <AuthWrapper>
      <Stack as="form" onSubmit={handleSubmit(submit)} spacing={6}>
        {formState.isSubmitSuccessful ? (
          <>
            <AuthHeader title="Email sent!" onClose={handleCloseClick} />

            <Text align="center" color="scheme.textSecondary">
              We just sent you a password reset link. Keep an eye out for that
              email.
            </Text>

            <Button
              type="submit"
              variant="solid"
              colorScheme="red"
              onClick={handleBackClick}
            >
              Take me back to log in
            </Button>
          </>
        ) : (
          <>
            <AuthHeader
              title="Recover password"
              onBack={handleBackClick}
              onClose={handleCloseClick}
            />

            <ErrorMessage
              errors={{ globalError }}
              name="globalError"
              as={<Text color="scheme.textError" />}
            />

            <Text align="center" color="scheme.textSecondary">
              Enter your email address and we’ll send you a link to reset your
              password.
            </Text>

            <Field label="Email address" error={formErrors.email?.message}>
              <TextInput
                type="text"
                autoComplete="email"
                name="email"
                placeholder="example@outdoorly.com"
                ref={register({
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Must be a valid email',
                  },
                })}
                onChange={(input) => {
                  setValue('email', input?.target?.value?.trim());
                }}
              />
            </Field>

            <Button type="submit" variant="solid" colorScheme="red">
              Send password reset link
            </Button>

            <Stack direction="row" justify="center" spacing="1">
              <Text color="scheme.textSecondary">I remember it!</Text>
              <Button
                colorScheme="red"
                variant="link"
                onClick={handleBackClick}
              >
                Take me back
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </AuthWrapper>
  );
};
