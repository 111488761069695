import React, { useState, useCallback } from 'react';
import { Button } from '@outdoorly/button';
import { Text } from '@outdoorly/text';
import { Field, TextInput } from '@outdoorly/input';
import { HStack, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { SocialLogin } from './socialLogin';
import { useAuth } from '../../providers/auth';
import { EMAIL_REGEX } from '../../globals';

import { AuthHeader, SocialAuthDivider } from './shared';
import { AuthWrapper } from './auth-wrapper';
import { NextSeo } from 'next-seo';

export const Login = ({
  isLoading = false,
  onLogin = () => {
    /** noop */
  },
  handleSignupClick,
  handleForgotPassword,
  handleCloseClick,
}: LoginProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    errors: formErrors,
    setError: setFormError,
    setValue,
  } = useForm();

  const [globalError, setGlobalError] = useState<
    { message: string } | undefined
  >();

  const auth = useAuth();

  const onSuccess = useCallback(() => {
    onLogin();
  }, [onLogin]);

  const onError = useCallback(
    (error) => {
      if (error.errors?.length) {
        error.errors.forEach(({ field, message }) => {
          if (field) {
            setFormError(field, {
              type: 'required',
              message,
            });
          } else {
            setGlobalError({ message });
          }
        });
      } else {
        setGlobalError({ message: 'Unable to login. Please try again.' });
      }
      console.error(error);
    },
    [setFormError]
  );

  const submit = useCallback(
    async ({ email, password }) => {
      // Reset any errors which might be present
      setGlobalError(undefined);
      try {
        await auth
          .login({
            email,
            password,
          })
          .then(onSuccess);
      } catch (error) {
        onError(error);
      }
    },
    [auth, onSuccess, onError, setGlobalError]
  );

  return (
    <>
      <NextSeo
        title={`Log in | Outdoorly`}
        description={
          'Welcome back to Outdoorly. Login now to enjoy pro deals on outdoor gear from top retailers.'
        }
        openGraph={{
          url: `https://www.outdoorly.com/login`,
          title: 'Log in | Outdoorly',
          description:
            'Welcome back to Outdoorly. Login now to enjoy pro deals on outdoor gear from top retailers.',
        }}
      />
      <AuthWrapper>
        <Stack as="form" onSubmit={handleSubmit(submit)} spacing={6}>
          <AuthHeader title="Log in to Outdoorly!" onClose={handleCloseClick} />
          <SocialLogin />
          <SocialAuthDivider />

          {auth.error && <Text color="scheme.textError">{auth.error}</Text>}
          {globalError && (
            <Text color="scheme.textError">{globalError.message}</Text>
          )}

          <Stack spacing={3}>
            <Field label="Email" error={formErrors.email?.message}>
              <TextInput
                type="text"
                autoComplete="email"
                name="email"
                placeholder="Email"
                ref={register({
                  required: 'Email is required',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Must be a valid email',
                  },
                })}
                onChange={(input) => {
                  setValue('email', input?.target?.value?.trim());
                }}
              />
            </Field>

            <Field label="Password" error={formErrors.password?.message}>
              <TextInput
                type="password"
                autoComplete="current-password"
                name="password"
                placeholder="Password"
                ref={register({ required: 'Password is required' })}
              />
            </Field>

            <Button
              variant="link"
              onClick={handleForgotPassword}
              alignSelf="flex-end"
            >
              Forgot password?
            </Button>
          </Stack>

          <Button
            type="submit"
            variant="solid"
            colorScheme="red"
            isLoading={auth.isLoading || isLoading}
          >
            Log in
          </Button>

          <HStack spacing={4} justify="center">
            <Text color="scheme.textSecondary">Not a member?</Text>
            <Button
              colorScheme="red"
              variant="link"
              onClick={handleSignupClick}
            >
              Sign up
            </Button>
          </HStack>
        </Stack>
      </AuthWrapper>
    </>
  );
};

export type LoginProps = {
  handleCloseClick: () => void;
  handleForgotPassword: () => void;
  handleSignupClick: () => void;
  isLoading?: boolean;
  onLogin?: () => void;
};
