import React from 'react';
import { Box, Fade, Flex, Stack } from '@chakra-ui/react';
import { LogoWordmarkRemarkCompany } from '@outdoorly/logo';
import { Text } from '@outdoorly/text';
import NextImage from 'next/image';
// eslint-disable-next-line
// @ts-ignore
import backgroundImage from '../../public/images/login/signup-background.jpg';

export type AuthWrapperProps = {
  children: React.ReactNode;
  isSingleColumn?: boolean;
};

export const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  isSingleColumn,
}) => {
  return (
    <Flex
      w="100%"
      minH="100vh" // ensure the background image fills the screen
      justify="center"
      align={['flex-start', 'center']}
    >
      <NextImage
        layout="fill"
        objectFit="cover"
        alt="decorative background picture"
        objectPosition="center"
        src={backgroundImage}
        placeholder="blur"
        className="smooth-filter"
      />
      <Box
        background={'rgba(0, 0, 0, 0.4)'}
        width={'100%'}
        height={'100%'}
        position="absolute"
      />

      <Flex
        w="100%"
        maxW="8xl"
        direction="row"
        align={['flex-start', 'center']}
        justify="center"
        position="relative"
        p={[0, 'gutter']}
        mx={['0', '8', '16', '32']}
      >
        {!isSingleColumn && (
          <Stack
            direction="column"
            spacing={6}
            paddingX={16}
            width="100%"
            textAlign="left"
            display={['none', 'none', 'none', 'none', 'flex']}
          >
            <LogoWordmarkRemarkCompany
              width="100%"
              color="scheme.textPrimaryInverse"
              maxW="48"
            />
            <Text size="6xl" fontWeight="500" color="scheme.textPrimaryInverse">
              Welcome
            </Text>
            <Text size="xl" color="scheme.textPrimaryInverse">
              Rewarding outdoor experts with exclusive perks and discounts.
            </Text>
          </Stack>
        )}

        <Flex justify="center" height={['100vh', 'auto']} width={'100%'}>
          <Fade in={true} style={{ width: '100%' }}>
            <Box
              align="center"
              bg="scheme.bgPrimary"
              borderRadius={['none', '2xl']}
              boxShadow={['none', 'md']}
              padding={['gutter', 8]}
              width="100%"
              maxWidth="lg"
              height="100%"
              mx="auto"
            >
              {children}
            </Box>
          </Fade>
        </Flex>
      </Flex>
    </Flex>
  );
};
