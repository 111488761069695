import React from 'react';
import { IconButton, ODCloseButton } from '@outdoorly/button';
import { Text } from '@outdoorly/text';
import { ArrowLeftIcon } from '@outdoorly/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { Divider } from '@outdoorly/divider';

export type AuthHeaderProps = {
  onBack?: () => void;
  onClose?: () => void;
  title: string;
};

export const AuthHeader: React.FC<AuthHeaderProps> = ({
  onBack,
  onClose,
  title,
}) => {
  return (
    <Flex width="100%" align="center" justify="space-between">
      <Box width="10" height="10">
        {onBack && (
          <IconButton
            aria-label="Back"
            variant="ghost"
            icon={<ArrowLeftIcon />}
            onClick={onBack}
            isRound
          />
        )}
      </Box>
      <Text size="2xl" fontWeight="500">
        {title}
      </Text>
      <Box width="10" height="10" position="relative">
        {onClose && (
          <ODCloseButton aria-label="Close" onClick={onClose} size="md" />
        )}
      </Box>
    </Flex>
  );
};

export const SocialAuthDivider: React.FC<unknown> = () => (
  <Stack direction="row" align="center">
    <Divider />
    <Text color="scheme.textSecondary">Or</Text>
    <Divider />
  </Stack>
);
