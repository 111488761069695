import React, { useState, useCallback, useEffect } from 'react';
import { Button } from '@outdoorly/button';
import { Text } from '@outdoorly/text';
import { Field, TextInput } from '@outdoorly/input';
import { Stack, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Marking } from '@markings/react';

import { initializeApollo } from '../../lib/auth/apollo';
import { SocialLogin } from './socialLogin';
import { EMAIL_REGEX } from '../../globals';
import { AuthHeader, SocialAuthDivider } from './shared';
import { AuthWrapper } from './auth-wrapper';
import { signUpWithEmail } from '../../providers/auth';
import { useRouter } from 'next/router';
import { getRedirectUrl } from '@outdoorly/util';
import { UPDATE_NAME_MUTATION } from '../profile/account-settings';
import { enterCode } from '../post-sign-in-congratulator';
import { NextSeo } from 'next-seo';
import axios from 'axios';
import { UPDATE_REGION } from '../../lib/graphql/addresses/mutations';

export type SignUpProps = {
  handleCloseClick: () => void;
  handleLoginClick: () => void;
  invitationCode?: string;
  isLoading?: boolean;
  onLogin?: () => void;
};

export const SignUp: React.FC<SignUpProps> = ({
  invitationCode,
  isLoading: isRedirecting = false,
  handleCloseClick,
  handleLoginClick,
}) => {
  const router = useRouter();
  const redirectTo = getRedirectUrl(router.query.next_url);

  const {
    register,
    handleSubmit,
    errors: formErrors,
    setError: setFormError,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const [globalError, setGlobalError] = useState<
    { message: string } | undefined
  >();
  const [inviteCodeFieldVisibility, setInviteCodeVisibility] = useState<
    'closed' | 'open' | 'hidden'
  >('closed'); // 'closed', 'open', 'hidden'

  useEffect(() => {
    if (invitationCode) {
      setInviteCodeVisibility('open');
      setValue('invitationCode', invitationCode);
    }
  }, [invitationCode, setValue]);

  const onError = useCallback(
    (error) => {
      if (error.errors?.length) {
        error.errors.forEach(({ field, message }) => {
          if (field) {
            setFormError(field, {
              type: 'required',
              message,
            });
          } else {
            setGlobalError({ message });
          }
        });
      } else {
        setGlobalError({ message: 'Unable to sign up. Please try again.' });
      }
      console.error(error);
    },
    [setFormError]
  );

  const submit = useCallback(
    async (values) => {
      // Reset any errors which might be present
      setGlobalError(undefined);

      await signUpWithEmail(values.email, values.password)
        .then(async () => {
          try {
            const apolloClient = initializeApollo();
            await apolloClient
              .mutate({
                mutation: UPDATE_NAME_MUTATION,
                variables: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
              })
              .then(async () => {
                if (values.invitationCode) {
                  enterCode(values.invitationCode);
                }
              })
              .then(async () => {
                const countryCode = await axios
                  .get('/api/countryCode')
                  .then((resp) => resp.data)
                  .catch(() => {
                    console.log('Error getting country code');
                  });
                if (countryCode === 'CA') {
                  apolloClient.mutate({
                    mutation: UPDATE_REGION,
                    variables: {
                      newRegion: 'CA',
                    },
                  });
                }
              });
          } finally {
            router.push(redirectTo);
          }
        })
        .catch((error) => onError({ errors: [{ message: error }] }));
    },
    [onError, redirectTo, router]
  );

  return (
    <>
      <NextSeo
        title={`Sign up | Outdoorly`}
        description={
          'Welcome to Outdoorly. Login now to enjoy pro deals on outdoor gear from top retailers.'
        }
        openGraph={{
          url: `https://www.outdoorly.com/signup`,
          title: 'Sign up | Outdoorly',
          description:
            'Welcome to Outdoorly. Login now to enjoy pro deals on outdoor gear from top retailers.',
        }}
      />
      <AuthWrapper>
        <Stack as="form" onSubmit={handleSubmit(submit)} spacing={6}>
          <AuthHeader title="Join Outdoorly!" onClose={handleCloseClick} />
          <SocialLogin />
          <SocialAuthDivider />

          <ErrorMessage
            errors={{ globalError }}
            name="globalError"
            as={<Text color="scheme.textError" />}
          />

          <Field label="First name" error={formErrors.name?.message}>
            <TextInput
              type="text"
              autoComplete="given-name"
              name="firstName"
              placeholder="First name"
              ref={register({
                required: 'First name is required',
              })}
            />
          </Field>

          <Field label="Last name" error={formErrors.name?.message}>
            <TextInput
              type="text"
              autoComplete="family-name"
              name="lastName"
              placeholder="Last name"
              ref={register({
                required: 'Last name is required',
              })}
            />
          </Field>

          <Field label="Email address" error={formErrors.email?.message}>
            <TextInput
              type="text"
              autoComplete="email"
              name="email"
              placeholder="Email"
              ref={register({
                required: 'Email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Must be a valid email',
                },
              })}
              onChange={(input) => {
                setValue('email', input?.target?.value?.trim());
              }}
            />
          </Field>

          <Field label="Password" error={formErrors.password?.message}>
            <TextInput
              type="password"
              autoComplete="current-password"
              name="password"
              placeholder="Password"
              ref={register({ required: 'Password is required' })}
            />
          </Field>

          {inviteCodeFieldVisibility === 'closed' && (
            <Marking purpose="fixme" description="Button color is too light">
              {(markingProps) => (
                <Button
                  variant="link"
                  onClick={() => setInviteCodeVisibility('open')}
                  alignSelf="flex-start"
                  {...markingProps}
                >
                  Add invitation code
                </Button>
              )}
            </Marking>
          )}

          <Field
            display={inviteCodeFieldVisibility === 'open' ? undefined : 'none'}
            label="Invitation code"
            error={formErrors.invitationCode?.message}
          >
            <TextInput
              placeholder="YOURCODE"
              type="text"
              name="invitationCode"
              ref={register({})}
            />
          </Field>

          <Button
            type="submit"
            variant="solid"
            colorScheme="red"
            isLoading={isRedirecting || isSubmitting}
          >
            Create account
          </Button>

          <Text color="scheme.textSecondary">
            Already a member?{' '}
            <Button colorScheme="red" variant="link" onClick={handleLoginClick}>
              Log in
            </Button>
          </Text>

          <Text size="sm" color="scheme.textSecondary">
            By clicking Create Account, you agree to Outdoorly’s{' '}
            <Box as="a" href="/about/terms" textDecoration="underline">
              Terms
            </Box>{' '}
            and{' '}
            <Box as="a" href="/about/privacy" textDecoration="underline">
              Privacy Policy
            </Box>
          </Text>
        </Stack>
      </AuthWrapper>
    </>
  );
};
